<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <div class="container mx-2">
      <!-- Breadcrumb  -->
      <va-card>
        <va-card-content>
          <div style="display: flex">
            <va-breadcrumbs color="primary">
              <va-breadcrumbs-item :label="$t('menu.appeals')" />
            </va-breadcrumbs>
          </div>
        </va-card-content>
      </va-card>
      <div class="mt-3">
        <div class="flex">
          <va-card stripe stripe-color="#1f1f61">
            <va-card-content>
              <h1
                v-if="appeals.length == 0"
                class="text-center"
                style="font-size: 32px"
              >
                {{ $t("no_appeals") }}
                <va-icon class="material-icons" size="32px"> schedule </va-icon>
              </h1>
              <va-list v-else>
                <va-list-item
                  v-for="(appeal, index) in appeals"
                  :key="appeal.name"
                  :to="{ name: 'appeal', params: { id: appeal.id } }"
                  class="flex justify--space-around"
                >
                  <strong>{{ index + offset + 1 }}</strong>

                  <va-list-item-section>
                    <va-list-item-label>
                      <strong>{{ appeal.subject }}</strong>
                    </va-list-item-label>
                  </va-list-item-section>

                  <va-list-item-section icon v-if="appeal.status">
                    <va-icon :name="appeal.status?'done':'close'" color="#0a4695" />
                  </va-list-item-section>
                </va-list-item>
              </va-list>
            </va-card-content>
          </va-card>
          <va-pagination
            v-if="pages > 1"
            color="#0a4695"
            style="direction: ltr"
            class="mt-5"
            v-model="currentPage"
            :visible-pages="3"
            :pages="pages"
          />
        </div>
      </div>
    </div>
  </va-inner-loading>
</template>

<script>
import { request, gql } from "graphql-request";
export default {
  data() {
    return {
      appeals: [],
      AppealsCount: null,
      pages: null,
      currentPage: 1,
      limit: 10,
      offset: 0,
      loading: true,
    };
  },
  async mounted() {
    // Pagination Logic
    try {
      const APPEALS_COUNT = gql`
        query {
          AppealsCount {
            count
          }
        }
      `;
      const counterResponse = await request(
        this.$store.state.appUrl,
        APPEALS_COUNT
      );
      this.AppealsCount = counterResponse.AppealsCount.count;
      this.pages = Math.ceil(this.AppealsCount / this.limit);
      this.getAppeals();
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.loading = false;
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("errors.network"),
          text: "Try Again in a minute",
          confirmButtonColor: "#023b81",
          confirmButtonText: "Reload",
        })
        .then((result) => {
          if ((result.value = true)) {
            location.reload();
          }
        });
    }
  },
  methods: {
    async getAppeals() {
      this.loading = true;
      this.offset = (this.currentPage - 1) * this.limit;
      const GET_ALL_APPEALS = gql`
        query ($offset: Int, $limit: Int) {
          Appeals(limit: $limit, offset: $offset) {
            id
            team_id
            subject
            body
            status
            decision
            decision_time
            commity1
            commity2
            commity3
          }
        }
      `;
      try {
        const response = await request(
          this.$store.state.appUrl,
          GET_ALL_APPEALS,
          { limit: this.limit, offset: this.offset }
        );
        this.appeals = response.Appeals;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$swal
          .fire({
            icon: "warning",
            title: this.$t("errors.network"),
            text: "Try Again in a minute",
            confirmButtonColor: "#023b81",
            confirmButtonText: "Reload",
          })
          .then((result) => {
            if ((result.value = true)) {
              location.reload();
            }
          });
      }
    },
  },
  watch: {
    async currentPage() {
      await this.getAppeals();
    },
  },
};
</script>
